import { useMoralisDapp } from "providers/MoralisDappProvider/MoralisDappProvider";
import { useMoralis } from "react-moralis";
import { getEllipsisTxt } from "helpers/formatters";
import Blockie from "../Blockie";
import { Button, Card, Modal } from "antd";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Address from "../Address/Address";
import { CodeSandboxCircleFilled, SelectOutlined } from "@ant-design/icons";
import { getExplorer } from "helpers/networks";
import {connectors} from "./config";
import "./style.css";
import Text from "antd/lib/typography/Text";

const styles = {
  connector: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "auto",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "20px 5px",
    cursor: "pointer",
  },
  icon: {
    alignSelf: "center",
    fill: "rgb(40, 13, 95)",
    flexShrink: "0",
    marginBottom: "8px",
    height: "30px",
  },
  account: {
    height: "42px",
    padding: "0 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    borderRadius: "12px",
   
    cursor: "pointer",
  },
  text: {
    color: "white",
    marginBottom: "0px",
    fontSize: "0.95rem",
    fontWeight: "600",
  },
};

function Account() {
  const history = useHistory();
  const { authenticate, isAuthenticated, account, logout, Moralis } = useMoralis();
  const { walletAddress, chainId } = useMoralisDapp();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAuthModalVisible, setIsAuthModalVisible] = useState(false);

  const handleConnectWalletBtnClick = async () => {

    await Moralis.enableWeb3();
    const chainId = await Moralis.getChainId();
    if (chainId != 56) {
      warningWrongNetwork();
      return;
    }
    setIsAuthModalVisible(true)
  }
  
  const connect = async (connectorId) => {
    try {
      await authenticate({provider: connectorId, signingMessage: 'Sign in SafeMars NFT MarketPlace'});
      window.localStorage.setItem("connectorId", connectorId);
      setIsAuthModalVisible(false);
    } catch (e) {
      console.error(e);
    }
  }
  

  const disconnect = async () => {
    await logout();
    window.localStorage.removeItem("connectorId");
    setIsModalVisible(false);
    history.push('/NFTMarketPlace');
  }

  function warningWrongNetwork() {
    let secondsToGo = 5;
    const modal = Modal.info({
      title: "Wrong Network",
      content: `Please change the network into Binance Smart Chain Network`,
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  if (!isAuthenticated) {
    return (
      <>
        <div
          style={styles.account}
          onClick={() => handleConnectWalletBtnClick()}
        >
          <p className="btn btn-gradient-primary btn-sm px-3 d-lg-flex align-items-center" style={styles.text}>
            <i className="las la-wallet me-2 transform-none"></i>
            Authenticate
          </p>
        </div>

        <Modal
        visible={isAuthModalVisible}
        footer={null}
        onCancel={() => setIsAuthModalVisible(false)}
        bodyStyle={{
          padding: "15px",
          fontSize: "17px",
          fontWeight: "500",
        }}
        style={{fontSize: "16px", fontWeight: "500"}}
        width="340px"
        >
        <div
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            fontWeight: "700",
            fontSize: "20px",
          }}
        >
          Connect Wallet
        </div>
        <div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
          {connectors.map(({title, icon, connectorId}, key) => (
            <div
              style={styles.connector}
              key={key}
              onClick={() => connect(connectorId)}
            >
              <img src={icon} alt={title} style={styles.icon} />
              <Text style={{fontSize: "14px"}}>{title}</Text>
            </div>
          ))}
        </div>
        </Modal>
      </>
    );
  }

  return (
    <>
      <li className="nav-item" onClick={() => setIsModalVisible(true)}>
        <a className="nav-link ">{getEllipsisTxt(walletAddress, 6)}</a>
      </li>
      
      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        bodyStyle={{
          padding: "15px",
          fontSize: "17px",
          fontWeight: "500",
        }}
        style={{ fontSize: "16px", fontWeight: "500" }}
        width="400px"
      >
        Account
        {/* <Card
          style={{
            marginTop: "10px",
            borderRadius: "1rem",
          }}
          bodyStyle={{ padding: "15px" }}
        >
          <Address
            avatar="left"
            size={6}
            copyable
            style={{ fontSize: "20px" }}
          />
          <div style={{ marginTop: "10px", padding: "0 10px" }}>
            <a
              href={`${getExplorer(chainId)}/address/${walletAddress}`}
              target="_blank"
              rel="noreferrer"
            >
              <SelectOutlined style={{ marginRight: "5px" }} />
              View on Explorer
            </a>
          </div>
        </Card> */}
        <Button
          size="large"
          type="primary"
          style={{
            width: "100%",
            marginTop: "10px",
            borderRadius: "0.5rem",
            fontSize: "16px",
            fontWeight: "500",
          }}
          onClick={() => disconnect()}
        >
          Disconnect Wallet
        </Button>
      </Modal>
    </>
  );
}

export default Account;
