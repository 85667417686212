import { useEffect, useState} from "react";
import { useMoralis } from "react-moralis";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
  Link
} from "react-router-dom";
import Account from "components/Account";
import Chains from "components/Chains";
import NFTBalance from "components/NFTBalance";
import NFTTokenIds from "components/NFTTokenIds";
import SingleItem from "components/SingleItem";
import MainHome from "components/MainHome";

import CollectionAdd from "components/CollectionAdd";
import { Menu, Layout} from "antd";
import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';

import SearchCollections from "components/SearchCollections";
import "antd/dist/antd.css";
import NativeBalance from "components/NativeBalance";
//import "./style.css";

import bootstrap_css from "bootstrap/bootstrap.css";
import default_style from "css/style.default.css";
import custom from "css/custom.css";
import "vendor/swiper/swiper-bundle.min.css";

import whitepaperDe from 'whitepaper/de-safemars-whitepaper.pdf';
import whitepaperEn from 'whitepaper/en-safemars-whitepaper.pdf';
import whitepaperEs from 'whitepaper/es-safemars-whitepaper.pdf';



import Text from "antd/lib/typography/Text";
import NFTMarketTransactions from "components/NFTMarketTransactions";
const { Header, Footer } = Layout;

const { SubMenu } = Menu;

const styles = {
  content: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Roboto, sans-serif",
    color: "#041836",
    marginTop: "130px",
    padding: "10px",
  },
  header: {
    position: "fixed",
    zIndex: 1,
    width: "100%",
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Roboto, sans-serif",
    borderBottom: "2px solid rgba(0, 0, 0, 0.06)",
    padding: "0 10px",
    boxShadow: "0 1px 10px rgb(151 164 175 / 10%)",
  },
  headerRight: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    fontSize: "15px",
    fontWeight: "600",
  },
};

const App = ({ isServerInfo }) => {
  const { isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading } =
    useMoralis();

  const [inputValue, setInputValue] = useState("explore");
  const [imageURL, setImageURL] = useState("");
  const [collectionName, setCollectionName] = useState("");
  const [nft, setNFT] = useState(null);
  const [viewType, setViewType] = useState(0); // 0: approve nft, 1: list, 2: approve safemars, 3: buy

  useEffect(() => {
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) enableWeb3();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);



  return (
    <Layout style={{ height: "100vh", overflow: "auto" }}>
      <Router>
      <nav  id="navbar" className="navbar navbar-expand-lg navbar-dark fixed-top navbar-active">
        <div className="container">
          <Link className="navbar-brand" to="/MainHome"><img className="img-fluid" src={"img/logo.svg"} alt="CR" width="140" /></Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <Menu
              theme="light"
              mode="horizontal"
            
              defaultSelectedKeys={["nftMarket"]}
              className="navbar-nav ms-auto mb-2 mb-lg-0 flex-lg-row align-items-lg-center"
            >
            <Menu.Item key="mainHome" className="nav-item">
              <NavLink to="/MainHome" className="nav-link">Home</NavLink>
            </Menu.Item>
            <Menu.Item key="casino" className="nav-item">
              <NavLink to="/casino" className="nav-link">Casino</NavLink>
            </Menu.Item>
            <Menu.Item key="collectionAdd" className="nav-item">
              <NavLink to="/CollectionAdd" className="nav-link">Collection Add</NavLink>
            </Menu.Item>
            <Menu.Item key="nftMarket" onClick={() => setInputValue("explore")} className="nav-item">
              <NavLink to="/NFTMarketPlace" className="nav-link">Explore Market</NavLink>
            </Menu.Item>
            <Menu.Item key="nft" className="nav-item">
              <NavLink to="/nftBalance" className="nav-link">Your Collection</NavLink>
            </Menu.Item>

            <li class="nav-item dropdown header_dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                White Papers 
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" target="_blank" href={whitepaperEn}>EN - Whitepaper</a>
                <a class="dropdown-item" target="_blank" href={whitepaperDe}>DE - Whitepaper</a>
                <a class="dropdown-item" target="_blank" href={whitepaperEs}>ES - Whitepaper</a>
              </div>
            </li>
            <Menu.Item key="transactions" className="nav-item" style={{display: 'none'}}>
              <NavLink to="/Transactions" className="nav-link">Your Transactions</NavLink>
            </Menu.Item>
            <Chains />
            <NativeBalance />
            <Account />
          </Menu>
        </div>
        </div>
      </nav>
      <div>
        <Switch>
        <Route path="/whitepaper1">
          <MainHome />
        </Route>
        <Route path="/mainHome">
          <MainHome />
        </Route>
        <Route path='/casino' component={() => { 
              window.open('https://safemars.casino/', '_blank');
              return null;
        }}>
        </Route>
          <Route path="/collectionAdd">
            <CollectionAdd />
          </Route>
          <Route path="/nftBalance">
            <NFTBalance setNFT={setNFT} setViewType={setViewType}/>
          </Route>
          <Route path="/NFTMarketPlace">
            <NFTTokenIds 
              inputValue={inputValue} 
              setInputValue={setInputValue} 
              imageURL={imageURL} 
              setImageURL={setImageURL} 
              collectionName={collectionName}
              setCollectionName={setCollectionName}
              setNFT={setNFT} 
              setViewType={setViewType}
            />
          </Route>
          <Route path="/Transactions">
            <NFTMarketTransactions />
          </Route>
          <Route path="/SingleItem/:tokenAddr/:tokenId">
            <SingleItem nft={nft} viewType={viewType}/>
          </Route>
        </Switch>
        <Redirect to="/NFTMarketPlace" />
      </div>
      </Router>

      <footer className="footer bg-map bg-dark">
        <div className="container py-4">
          <div className="row text-center">
            <p className="text-muted text-sm mb-0">© 2021 SafeMars & SafeEarth Ltd. All rights reserved <span className="text-primary">SafeMarsNFT.</span></p>
          </div>
        </div>
      </footer>
      
    </Layout>

    

  );
};

export const Logo = () => (
  <div style={{ display: "flex" }}>
    <svg
      width="60"
      height="38"
      viewBox="0 0 50 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.6871 32.3986C43.5973 32.4884 43.53 32.5782 43.4402 32.6905C43.53 32.6007 43.5973 32.5109 43.6871 32.3986Z"
        fill="black"
      />
      <path
        d="M49.7037 14.3715C49.5241 6.2447 42.7891 -0.17592 34.6624 0.00367768C31.0031 0.0934765 27.4784 1.53026 24.8294 4.06708C22.113 1.46291 18.4986 0.00367768 14.727 0.00367768C6.71246 0.00367768 0.202047 6.49164 0 14.5511V14.6633C0 20.8146 2.24497 26.2698 4.26545 30.0189C5.11853 31.5904 6.08387 33.117 7.13901 34.5762C7.5431 35.115 7.8574 35.564 8.10435 35.8559L8.39619 36.2151L8.48599 36.3273L8.50844 36.3498L8.53089 36.3722C10.2146 38.3253 13.1555 38.5498 15.1087 36.8886C15.1311 36.8661 15.1536 36.8437 15.176 36.8212C17.1291 35.0701 17.3312 32.0843 15.625 30.1087L15.6026 30.0638L15.423 29.8618C15.2658 29.6597 15.0189 29.3455 14.727 28.9414C13.9188 27.8189 13.178 26.6515 12.5269 25.4392C10.8881 22.4309 9.42888 18.6145 9.42888 14.7531C9.49623 11.8347 11.9432 9.52236 14.8617 9.58971C17.7128 9.65705 19.9802 11.9694 20.0251 14.8205C20.0476 15.5389 20.2272 16.2348 20.5415 16.8859C21.4844 19.3104 24.2232 20.5227 26.6478 19.5798C28.4438 18.8839 29.6336 17.1553 29.6561 15.2246V14.596C29.7683 11.6775 32.2153 9.38766 35.1562 9.47746C37.94 9.56726 40.1625 11.8122 40.2748 14.596C40.2523 17.6941 39.2645 20.7472 38.1421 23.1718C37.6931 24.1371 37.1992 25.08 36.6379 25.978C36.4359 26.3147 36.2787 26.5617 36.1665 26.6964C36.1216 26.7862 36.0767 26.8311 36.0542 26.8535L36.0318 26.876L35.9869 26.9433C37.6033 24.9004 40.5442 24.5412 42.5871 26.1576C44.4953 27.6617 44.9443 30.3781 43.6198 32.4211L43.6422 32.4435V32.3986L43.6647 32.3762L43.732 32.2864C43.7769 32.1966 43.8667 32.1068 43.9565 31.9721C44.1361 31.7027 44.3606 31.3435 44.6525 30.8945C45.3933 29.6822 46.0668 28.4026 46.673 27.1229C48.1097 24.0249 49.6812 19.5349 49.6812 14.5286L49.7037 14.3715Z"
        fill="#041836"
      />
      <path
        d="M39.7135 25.1249C37.1094 25.1025 34.9991 27.2127 34.9766 29.8169C34.9542 32.4211 37.0645 34.5313 39.6686 34.5538C41.1503 34.5538 42.5647 33.8578 43.4626 32.6905C43.53 32.6007 43.5973 32.4884 43.6871 32.3986C45.1015 30.221 44.4729 27.3025 42.2953 25.9107C41.532 25.3943 40.634 25.1249 39.7135 25.1249Z"
        fill="#B7E803"
      />
    </svg>

  </div>
);

export default App;
