import { useNativeBalance } from "hooks/useNativeBalance";
import { n4 } from "helpers/formatters";

function NativeBalance(props) {
  const { balance, nativeName } = useNativeBalance(props);

  return (
          
        <li className="nav-item">
             <a className="nav-link " >{`${n4.format(
      balance.formatted
    )} ${nativeName}`}</a>
         </li>
    
  );
}

export default NativeBalance;
